import React from 'react'

import { Layout } from '@components/Layout'
import { SEO } from '@components/SEO'
import { Content } from '../screens/Terms'

const TermsPage = () => {
  return (

    <Layout>
      <SEO title="Terms & Conditions" />
      <Content />
    </Layout>

  )
}

export default TermsPage
