import React from 'react'

import { Box, Container, Flex } from '@components/Grid'
import { SectionTitle, Text } from '@components/Text'

const Content = () => (
  <Box as="section" bg="green.100" py={6} px={4} id="services">
    <Container>
      <SectionTitle color="primary.900">TÉRMINOS DE USO</SectionTitle>
      <Box bg="white" position="relative" px={6} py={6} zIndex="2" width="100%">
        <Text as="h3" color="primary.900">
          1. DATOS IDENTIFICATIVOS
        </Text>
        <Text variant="body">
          En cumplimiento con el deber de información recogido en artículo 10 de
          la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la
          Información y del Comercio Electrónico, a continuación se reflejan los
          siguientes datos:
          <br />
          La empresa titular de dominio web es AIBROK CONSULTING CORREDURIA DE
          SEGUROS (en adelante AIBROK), con domicilio a estos efectos en Calle
          Dunas de San Antón, 5, Chiclana de la Frontera (Cádiz), C.P. 11130,
          España.
          <br />
          Número de C.I.F.: B-42722082.
          <br />
          Email: info@aibrok.com.mx
        </Text>
        <Text variant="body">
          AIBROK CONSULTING CORREDURIA DE SEGUROS es una Correduría de Seguros
          inscrita en la DGSFYP con número de registro J-3936.
        </Text>
        <Text as="h3" color="primary.900">
          2. USUARIOS
        </Text>
        <Text variant="body">
          El acceso y/o uso de este portal de AIBROK atribuye la condición de
          USUARIO, que acepta, desde dicho acceso y/o uso, las Condiciones
          Generales de Uso aquí reflejadas. Las citadas Condiciones serán de
          aplicación independientemente de las Condiciones Generales de
          Contratación que en su caso resulten de obligado cumplimiento.
        </Text>
        <Text as="h3" color="primary.900">
          3. USO DEL PORTAL
        </Text>
        <Text variant="body">
          WWW.AIBROK.COM proporciona el acceso a multitud de informaciones,
          servicios, programas o datos (en adelante, “los contenidos”) en
          Internet pertenecientes a AIBROK o a sus licenciantes a los que el
          USUARIO pueda tener acceso. El USUARIO asume la responsabilidad del
          uso del portal. Dicha responsabilidad se extiende al registro que
          fuese necesario para acceder a determinados servicios o contenidos.
        </Text>
        <Text variant="body">
          El USUARIO se compromete a hacer un uso adecuado de los contenidos y
          servicios que AIBROK ofrece a través de su portal y con carácter
          enunciativo pero no limitativo, a no emplearlos para (i) incurrir en
          actividades ilícitas, ilegales o contrarias a la buena fe y al orden
          público; (ii) difundir contenidos o propaganda de carácter racista,
          xenófobo, pornográfico-ilegal, de apología del terrorismo o
          atentatorio contra los derechos humanos; (iii) provocar daños en los
          sistemas físicos y lógicos de AIBROK, de sus proveedores o de terceras
          personas, introducir o difundir en la red virus informáticos o
          cualesquiera otros sistemas físicos o lógicos que sean susceptibles de
          provocar los daños anteriormente mencionados; (iv) intentar acceder y,
          en su caso, utilizar las cuentas de correo electrónico de otros
          usuarios y modifícalo manipular sus mensajes.
        </Text>
        <Text as="h3" color="primary.900">
          4. PROPIEDAD INTELECTUAL E INDUSTRIAL
        </Text>
        <Text variant="body">
          AIBROK por sí o como cesionaria, es titular de todos los derechos de
          propiedad intelectual e industrial de su página web, así como de los
          elementos contenidos en la misma (a título enunciativo, imágenes,
          sonido, audio, vídeo, software o textos; marcas o logotipos,
          combinaciones de colores, estructura y diseño, selección de materiales
          usados, programas de ordenador necesarios para su funcionamiento,
          acceso y uso, etc.), titularidad de AIBROK o bien de sus licenciantes.
        </Text>
        <Text variant="body">
          Todos los derechos reservados. En virtud de lo dispuesto en los
          artículos 8 y 32.1, párrafo segundo, de la Ley de Propiedad
          Intelectual, quedan expresamente prohibidas la reproducción, la
          distribución y la comunicación pública, incluida su modalidad de
          puesta a disposición, de la totalidad o parte de los contenidos de
          esta página web, con fines comerciales, en cualquier soporte y por
          cualquier medio técnico, sin la autorización de AIBROK. El USUARIO se
          compromete a respetar los derechos de Propiedad Intelectual e
          Industrial titularidad de AIBROK.
        </Text>
        <Text variant="body">
          Podrá visualizar los elementos del portal e incluso imprimirlos,
          copiarlos y almacenarlos en el disco duro de su ordenador o en
          cualquier otro soporte físico siempre y cuando sea, única y
          exclusivamente, para su uso personal y privado. El USUARIO deberá
          abstenerse de suprimir, alterar, eludir o manipular cualquier
          dispositivo de protección o sistema de seguridad que estuviera
          instalado en el las páginas de AIBROK.
        </Text>
        <Text as="h3" color="primary.900">
          5. EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD
        </Text>
        <Text variant="body">
          AIBROK no se hace responsable, en ningún caso, de los daños y
          perjuicios de cualquier naturaleza que pudieran ocasionar, a título
          enunciativo: errores u omisiones en los contenidos, falta de
          disponibilidad del portal o la transmisión de virus o programas
          maliciosos o lesivos en los contenidos, a pesar de haber adoptado
          todas las medidas tecnológicas necesarias para evitarlo.
        </Text>
        <Text as="h3" color="primary.900">
          6. MODIFICACIONES
        </Text>
        <Text variant="body">
          AIBROK se reserva el derecho de efectuar sin previo aviso las
          modificaciones que considere oportunas en su portal, pudiendo cambiar,
          suprimir o añadir tanto los contenidos y servicios que se presten a
          través de la misma como la forma en la que éstos aparezcan presentados
          o localizados en su portal.
        </Text>
        <Text as="h3" color="primary.900">
          7. ENLACES
        </Text>
        <Text variant="body">
          En el caso de que en WWW.AIBROK.COM.MX se dispusiesen enlaces o
          hipervínculos hacía otros sitios de Internet, AIBROK no ejercerá
          ningún tipo de control sobre dichos sitios y contenidos. En ningún
          caso AIBROK asumirá responsabilidad alguna por los contenidos de algún
          enlace perteneciente a un sitio web ajeno, ni garantizará la
          disponibilidad técnica, calidad, fiabilidad, exactitud, amplitud,
          veracidad, validez y constitucionalidad de cualquier material o
          información contenida en ninguno de dichos hipervínculos u otros
          sitios de Internet.
          <br />
          Igualmente la inclusión de estas conexiones externas no implicará
          ningún tipo de asociación, fusión o participación con las entidades
          conectadas.
          <br />
          AIBROK se reserva el derecho de efectuar sin previo aviso las
          modificaciones que considere oportunas en su portal, pudiendo cambiar,
          suprimir o añadir tanto los contenidos y servicios que se presten a
          través de la misma como la forma en la que éstos aparezcan presentados
          o localizados en su portal.
        </Text>
        <Text as="h3" color="primary.900">
          8. DERECHO DE EXCLUSIÓN
        </Text>
        <Text variant="body">
          AIBROK se reserva el derecho a denegar o retirar el acceso a portal
          y/o los servicios ofrecidos sin necesidad de preaviso, a instancia
          propia o de un tercero, a aquellos usuarios que incumplan las
          presentes Condiciones Generales de Uso.
        </Text>
        <Text as="h3" color="primary.900">
          9. GENERALIDADES
        </Text>
        <Text variant="body">
          AIBROK perseguirá el incumplimiento de las presentes condiciones así
          como cualquier utilización indebida de su portal ejerciendo todas las
          acciones civiles y penales que le puedan corresponder en derecho.
        </Text>
        <Text as="h3" color="primary.900">
          10. MODIFICACIÓN DE LAS PRESENTES CONDICIONES Y DURACIÓN
        </Text>
        <Text variant="body">
          AIBROK podrá modificar en cualquier momento las condiciones aquí
          determinadas, siendo debidamente publicadas como aquí aparecen.
          <br />
          La vigencia de las citadas condiciones irá en función de su exposición
          y estarán vigentes hasta debidamente publicadas. que sean modificadas
          por otras.
        </Text>
        <Text as="h3" color="primary.900">
          11. LEGISLACIÓN APLICABLE Y JURISDICCIÓN
        </Text>
        <Text variant="body">
          La relación entre AIBROK y el USUARIO se regirá por la normativa
          española vigente y cualquier controversia se someterá a los Juzgados y
          tribunales de la ciudad de CÁDIZ.
        </Text>
      </Box>
    </Container>
  </Box>
)

export default Content
